export default {
  addresses: 'Addresses',
  addressName: 'Address Name',
  addressNameSearch: 'Search Address Name',
  addressSearch: 'Search Address',
  createAddress: 'Create Address',
  editAddress: 'Edit Address',
  general: 'General',
  transfers: 'Transfers',
  details: 'Details',
  fetching: 'Fetching data',
  nodata: 'Nothing\'s here...',
  transferList: 'Transfers List',
  newRequest: 'New Request',
  transferRequest: 'Transfer Request',
  transferDetails: 'Transfer Details',
  save: 'Save',
  total: 'Total',
  approve: 'Approve',
  aldim: 'I received the passenger',
  biraktim: 'I left the passenger',
  approved: 'Approved',
  canceled: 'Canceled',
  updated: 'Successfully updated.',
  created: 'Successfully created.',
  reject: 'Reject',
  cancelRequest: 'Cancel Request',
  yes: 'Yes',
  no: 'No',
  users: 'Users',
  board: 'Board',
  createUser: 'Create User',
  editUser: 'Edit User',
  edit: 'Edit',
  delete: 'Delete',
  loginFailed: 'Login Failed',
  username: 'Username',
  password: 'Password',
  passwordConfirmation: 'Password Confirmation',
  error: 'Error occurred while processing.',
  errorApproved: 'The request has been previously canceled and has not been approved.',
  createDepartment: 'Create Department',
  departmentName: 'Department Name',
  create: 'Create',
  close: 'Close',
  txtstatus: 'Status',
  search: 'Search',
  idSearch: 'ID Search',
  nameSearch: 'Name Search',
  usernameSearch: 'Username Search',
  emailSearch: 'Email Search',
  email: 'E-mail',
  emailAddress: 'E-mail Address',
  allRoles: 'All Roles',
  allDepartments: 'All Departments',
  phoneSearch: 'Phone Search',
  dateSearch: 'Pick Date Range',
  plateSearch: 'Plate Search',
  brandSearch: 'Brand Search',
  modelSearch: 'Model Search',
  brand: 'Brand',
  model: 'Model',
  totalVoyage: 'Voyage',
  totalDistance: 'KM',
  totalTime: 'Time',
  shuttleTime: 'Time',
  date: 'Date',
  departureSearch: 'Departure Search',
  departure: 'Departure',
  targetSearch: 'Target Search',
  driverSearch: 'Driver Search',
  all: 'All',
  transferDay: 'Transfer Day',
  targetAddress: 'Target Address',
  target: 'Target',
  startAddress: 'Start Address',
  address: 'Address',
  comment: 'Comment',
  aciklama: 'Comment',
  name: 'Name',
  fullname: 'Full Name',
  phoneNumber: 'Phone Number',
  phone: 'Phone',
  checkFormValidation: 'Please Check Form',
  transferCreated: 'Transfer Request Created',
  transferCopied: 'Transfer Copied. We are redirecting you..',
  copy: 'Copy',
  requestForm: 'Request Form',
  passenger: 'Passenger',
  addPassenger: 'Add Passenger',
  paginationShowing: 'Showing {1} - {2} of {0} entries',
  plate: 'Plate',
  driver: 'Driver',
  driverPhone: 'Driver Phone',
  chooseCar: 'Choose Car',
  chooseDriver: 'Choose Driver',
  carTxt: 'Car',
  clear: 'Clear',
  directions: 'Directions',
  estimatedTime: 'Est. Time',
  filters: 'Filters',
  transferDuration: 'Duration',
  transferDistance: 'KM',
  showStat: 'Show Stat',
  hideStat: 'Hide Stat',
  exportExcel: 'Export to Excel',
  importExcel: 'Import to Excel',
  clearFilter: 'Clear Filters',
  refresh: 'Refresh',
  uploadText: 'Drop your file here or click to upload',
  excelExampleFile: 'To download the sample transfer file',
  click: 'Click',
  transferInfo: 'Transfer Info',
  startLocation: 'Start Location',
  targetLocation: 'Target Location',
  start: 'Start',
  driverCarInfo: 'Driver & Car Info',
  rejectComment: 'Reject Comment',
  rejectUser: 'Reject User',
  personalInfo: 'Personal Info',
  fullName: 'Fullname',
  tranferDate: 'Transfer Date',
  createdDate: 'Created Date',
  userComment: 'User Comment',
  adminComment: 'Admin Comment',
  userForm: 'User Formu',
  selectDepartment: 'Select a department',
  selectRole: 'Select a Role',
  department: 'Department',
  role: 'Role',
  enterStartAdress: 'Enter Start Address',
  enterTargetAdress: 'Enter Target Address',
  startAdress: 'Start Address',
  targetAdress: 'Target Address',
  login: 'Login',
  sendResetLink: 'Send Reset Link',
  passwordRecovery: 'Password Recovery',
  forgotPassword: 'Forgot password?',
  company: 'Company',
  additionalPassengers: 'Additional Passengers',
  addDepartment: 'Add Department',
  operationFailed: 'Operation Failed. Please check your information.',
  recoveryLinkSend: 'Password reset email sent.',
  resetPassword: 'Reset Password',
  resetPasswordSuccess: 'Your password was successfully reset.',
  myProfile: 'My Profile',
  logOut: 'Log out',
  driverWarning: 'The selected driver has been assigned another task {0} on the same day.',
  carWarning: 'The selected car has been assigned to different missions {0} on the same day.',
  availableCars: 'Available Cars',
  availableDrivers: 'Available Drivers',
  plannedCars: 'Planned Cars',
  enrouteCars: 'Cars On The Road',
  completions: 'Completions',
  cars: 'Cars',
  carDetails: 'Car Details',
  editCar: 'Edit Car',
  seats: 'Seats',
  shuttlePlanCreate: 'New Shuttle Plan',
  shuttlePlanEdit: 'Edit Shuttle Plan',
  shuttlePlans: 'Shuttle Plans',
  shuttlePlanCreated: 'Shuttle Plan Successfully Created',
  shuttleRegisterList: 'Shuttles List',
  shuttleRegisterDetails: 'Register Details',
  shuttleCancel: 'Shuttle Request Canceled.',
  newShuttleRegisterRequest: 'New Shuttle Request',
  moveShuttle: 'Move Shuttle Register',
  route: 'Route',
  departureAddress: 'Departure Address',
  arrivalAddress: 'Arrival Address',
  location: 'Location',
  chooseLocation: 'Choose Location',
  choose: 'Choose',
  enter: 'Enter',
  exit: 'Exit',
  unassigned: 'Unassigned',
  success: 'Operation Successful',
  selected: 'Selected',
  daySelected: 'no day | 1 day selected | {count} days selected',
  dates: 'Dates',
  selfDriveRequest: 'Request for self drive transfer',
  type: 'Type',
  description: 'Description',
  transferMoveShuttle: 'The request has been changed as a service request by the Chief Driver. Shuttle Name: {0} Time: {1}',
  passengerOccupancyRate: 'Passenger Occupancy Rate',
  shifts: 'Shifts',
  createShift: 'Create Shift',
  editShift: 'Edit Shift',
  shiftName: 'Shift Name',
  owner: 'Owner',
  reviews: 'Reviews',
  rating: 'Rating',
  author: 'Author',
  ldapUser: 'LDAP User',
  notify: {
    approved: 'Your #{0} request is approved',
    rejected: 'Your #{0} request has been canceled',
    setDriver: '#{0} Appointed vehicle and driver for your request',
    leadRejected: 'Vehicle #{0} assigned transfer was canceled.',
    driverSet: 'Transfer request number #{0} has been assigned to you',
    driverRejected: 'Transfer #{0} has been canceled.',
    adminCreated: 'New transfer request has been created.',
    driverChanged: 'The driver of the transfer request #{0} has been changed.',
    driverPlateChanged: 'The license plate of the transfer request #{0} has been changed'
  },
  titles: {
    home: 'Home Page',
    login: 'Login Page',
    transfers: 'Transfers List',
    transfer: 'Transfer Details',
    users: 'Users List',
    createUser: 'Create User',
    transferRequest: 'Transfer Request',
    board: 'Board Page',
    newShuttlePlan: 'New Shuttle Plan',
    shuttleRegisterList: 'Shuttle Register List',
    shuttleRegister: 'Shuttle Register',
  },
  status: [
    'Waiting for Approval',
    'Vehicle to be Assigned',
    'Waiting for Departure',
    'En Route',
    'Completed',
    'Canceled'
  ],
  userStatus: {
    true: 'Active',
    false: 'Passive'
  },
  vehicleStatus: {
    true: 'Assigned',
    false: 'Waiting'
  },
  itemStatus: {
    true: 'Active',
    false: 'Passive',
  },
  selfDrive: {
    true: 'Self Drive',
    false: 'Normal',
  },
  dateTimePicker: {
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    dayNames: ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'S']
  },
  filter: {
    id: 'ID',
    name: 'Fullname',
    departure: 'Departure',
    target: 'Target',
    driver: 'Driver',
    plate: 'Plate',
    dateRange: 'Date',
    status: 'Status',
    brand: 'Brand',
    model: 'Model',
    type: 'Type',
    self_drive: 'Type',
    address: 'Address',
    address_name: 'Address Name',
    description: 'Description',
    created_at: 'Created At',
    starts_at: 'Starts At',
    ends_at: 'Ends At',
    shift_name: 'Shift Name',
    owner: 'Owner',
    user_department: 'Department',
  },
  smartTable: {
    enter: 'Enter',
    exit: 'Exit',
    active: 'Active',
    passive: 'Passive',
    true: 'On',
    false: 'Off',
  }
};
