<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a
        :title="toggleTooltip"
        class="navbar-item is-desktop-icon-only is-hidden-touch"
        @click.prevent="menuToggle"
      >
        <b-icon :icon="menuToggleIcon" />
      </a>
      <a class="navbar-item is-hidden-desktop" @click.prevent="menuToggleMobile">
        <b-icon :icon="menuToggleMobileIcon" />
      </a>
    </div>
    <div class="navbar-brand is-right">
      <lang-bar class="is-hidden-desktop" />
      <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop updates-icon" @click.prevent="updatesToggle">
        <b-icon icon="bell" class="updates-icon" custom-class="updates-icon" custom-size="default" />
      </a>
      <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="menuNavBarToggle">
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
      </a>
    </div>
    <div class="navbar-menu fadeIn animated faster" :class="{'is-active':isMenuNavBarActive}">
      <div class="navbar-end">
        <lang-bar class="is-hidden-mobile" />
        <nav-bar-menu class="has-divider">
          <!-- <b-icon icon="menu" custom-size="default"/> -->
          <span>{{ userName }}</span>
          <div slot="dropdown" class="navbar-dropdown">
            <router-link to="/profile" class="navbar-item">
              <b-icon icon="account" custom-size="default" />
              <span>{{ $t('myProfile') }}</span>
            </router-link>
          </div>
        </nav-bar-menu>
        <a
          class="navbar-item has-divider is-desktop-icon-only is-hidden-mobile updates-icon"
          title="Updates"
          :class="{'is-active':isAsideRightVisible}"
          @click.prevent="updatesToggle"
        >
          <b-icon
            :class="{'has-update-mark':hasUpdates}"
            class="updates-icon"
            custom-class="updates-icon"
            icon="bell"
            custom-size="default"
          />
          <span>Updates</span>
        </a>
        <a class="navbar-item is-desktop-icon-only" title="Log out" @click="logout">
          <b-icon icon="logout" custom-size="default" />
          <span>{{ $t('logOut') }}</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import NavBarMenu from '@/components/NavBarMenu'
import LangBar from '@/components/LangBar'

export default {
  name: 'NavBar',
  components: {
    NavBarMenu,
    LangBar
  },
  data () {
    return {
      isMenuNavBarActive: false,
    }
  },
  computed: {
    menuNavBarToggleIcon () {
      return (this.isMenuNavBarActive) ? 'close' : 'dots-vertical'
    },
    menuToggleMobileIcon () {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    menuToggleIcon () {
      return this.isAsideExpanded ? 'backburger' : 'forwardburger'
    },
    toggleTooltip () {
      return this.isAsideExpanded ? 'Collapse' : 'Expand'
    },
    ...mapState([
      'isNavBarVisible',
      'isAsideExpanded',
      'isAsideMobileExpanded',
      'isAsideRightVisible',
      'userName',
      'hasUpdates'
    ])
  },
  methods: {
    menuToggle () {
      this.$store.commit('asideStateToggle')
    },
    menuToggleMobile () {
      this.$store.commit('asideMobileStateToggle')
    },
    menuNavBarToggle () {
      this.isMenuNavBarActive = (!this.isMenuNavBarActive)
    },
    updatesToggle () {
      this.$store.commit('asideRightToggle')
    },
    logout () {
      this.$store.dispatch('logout')
        .then(() => this.$router.push('/login'))
        .catch(err => console.log(err));
    }
  }
}
</script>
