import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import each from 'lodash/each';
import createLogger from 'vuex/dist/logger';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: process.env.NODE_ENV === 'production' ? [] : [createLogger()],
  state: {
    /* Panel Company */
    panelCompanyName: 'Transporter',
    panelCompanySortName: 'TR',

    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideExpanded: false,
    isAsideMobileExpanded: false,
    asideActiveForcedKey: null,
    asideActiveMenuKey: '',
    isAsideRightVisible: false,

    /* Updates */
    hasUpdates: false,

    /* Overlay */
    isOverlayVisible: false,

    boardRefresh: false,

    /* User */
    status: '',
    token: localStorage.getItem('token') || '',
    user: { },
    userName: null,
    permissions: [],
    roles: [],
    departments: [],
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value;
    },

    /* Full Page mode */
    fullPage (state, payload) {
      state.isNavBarVisible = !payload;
      state.isAsideVisible = !payload;
      state.isFooterBarVisible = !payload;

      each(['has-aside-left', 'has-navbar-fixed-top'], htmlClass => {
        if (payload) {
          document.documentElement.classList.remove(htmlClass);
        } else {
          document.documentElement.classList.add(htmlClass);
        }
      });
    },

    /* Aside Desktop */
    asideStateToggle (state, payload = null) {
      const htmlAsideClassName = 'has-aside-expanded';

      let isExpand;

      if (payload !== null) {
        isExpand = payload;
      } else {
        isExpand = !state.isAsideExpanded;
      }

      if (isExpand) {
        document.documentElement.classList.add(htmlAsideClassName);
      } else {
        document.documentElement.classList.remove(htmlAsideClassName);
      }

      state.isAsideExpanded = isExpand;
    },

    /* Aside Mobile */
    asideMobileStateToggle (state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded';

      let isShow;

      if (payload !== null) {
        isShow = payload;
      } else {
        isShow = !state.isAsideMobileExpanded;
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName);
      } else {
        document.documentElement.classList.remove(htmlClassName);
      }

      state.isAsideMobileExpanded = isShow;
    },

    /* Aside Forced Active Key (when secondary submenu is open) */
    asideActiveForcedKeyToggle (state, payload) {
      state.asideActiveForcedKey = payload && payload.menuSecondaryKey ? payload.menuSecondaryKey : null;
    },

    setAsideActiveMenuKey (state, payload) {
      state.asideActiveMenuKey = payload;
    },

    boardRefresh (state, value) {
      state.boardRefresh = value;
    },

    /* Aside Right */
    asideRightToggle (state, payload = null) {
      const htmlClassName = 'has-aside-right';

      let isShow;

      if (payload !== null) {
        isShow = payload;
      } else {
        isShow = !state.isAsideRightVisible;
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName);
        axios.put('notifications/read');
      } else {
        document.documentElement.classList.remove(htmlClassName);
      }

      state.isAsideRightVisible = isShow;
      state.hasUpdates = false;
    },

    hasUpdates (state, payload = null) {
      if (payload) {
        state.hasUpdates = payload;
      } else {
        state.hasUpdates = !state.hasUpdates;
      }
    },

    setRoles (state, payload = null) {
      if (payload) {
        state.roles = [];
        payload.forEach((value) => {
          state.roles.push({
            id: value.id,
            name: value.attributes.name
          });
        });
      }
    },

    setDepartments (state, payload = null) {
      if (payload) {
        state.departments = [];
        payload.forEach((value) => {
          state.departments.push({
            id: value.id,
            name: value.attributes.name
          });
        });
      }
    },
    /* Overlay */
    overlayToggle (state, payload = null) {
      if (payload === null) {
        payload = !state.isOverlayVisible;
      }

      state.isOverlayVisible = !!payload;
    },
    auth_request (state) {
      state.status = 'loading';
    },
    auth_success (state, { token, user }) {
      state.status = 'success';
      state.token = token;
      state.user = user.data;
      state.permissions = user.data.attributes.permissions;
      state.userName = user.data.attributes.name;
    },
    auth_error (state) {
      state.status = 'error';
    },
    logout (state) {
      state.status = '';
      state.token = '';
      state.user = { };
      state.permissions = [];
      localStorage.setItem('token', '');
      localStorage.setItem('tenant_id', '');
      localStorage.setItem('base_url', '');
    },
    set_user (state, user) {
      state.user = user;
      state.permissions = user.attributes.permissions;
      state.userName = user.attributes.name;
    },
    setAppInfo (state, info) {
      state.panelCompanyName = info.appname;
      state.panelCompanySortName = info.appsortname;
    }
  },
  actions: {
    login ({ commit }, userData) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        const baseURL = '//' + userData.company + '.' + process.env.VUE_APP_MAIN_DOMAIN;
        axios.defaults.baseURL = `${baseURL}/api/`;
        window.localStorage.setItem('base_url', baseURL);

        axios.post('auth/login', userData)
          .then((results) => {
            const { data: { token, user, tenant_id } } = results;
            localStorage.setItem('token', token);
            localStorage.setItem('tenant_id', tenant_id);
            commit('auth_success', { token, user });
            resolve(results);
          })
          .catch((error) => {
            console.error(error);
            commit('auth_error');
            localStorage.removeItem('token');
            reject(error);
          });
      });
    },
    logout ({ commit }) {
      return new Promise((resolve) => {
        commit('logout');
        localStorage.removeItem('token');
        localStorage.removeItem('base_url');
        localStorage.removeItem('tenant_id');
        delete axios.defaults.headers.common.Authorization;
        resolve();
      });
    },
    getRoles ({ commit }) {
      axios.get('roles')
        .then(response => {
          commit('setRoles', response.data.data);
      });
    },
    async getDepartments ({ commit }) {
      try {
        const results = await axios.get('departments');
        commit('setDepartments', results.data.data);
      } catch (e) {
        // console.log('GetUserInfo Error => ', e);
      }
    },
    async getUserInfo ({ commit }) {
      try {
        const results = await axios.get('auth/me');
        const { data } = results.data;
        commit('set_user', data);
      } catch (e) {
        if (e.message.indexOf('401') > 0) {
          this.dispatch('logout');
          document.location = '/login';
        }
      }
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    authUser: state => state.user,
    authPermissions: state => state.permissions,
  }
});
