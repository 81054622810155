import axios from 'axios';


axios.defaults.baseURL = `${localStorage.getItem('base_url')}/api/`;

axios.interceptors.request.use((request) => {
  const token = localStorage.getItem('token');
  if (token) {
    // eslint-disable-next-line no-param-reassign
    request.headers.common.Authorization = `Bearer ${token}`;
    request.headers.common['X-Localization'] = localStorage.getItem('lang') || 'en';
  }
  return request;
});
