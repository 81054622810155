import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

Vue.use(Router);

function requireAuth (to, from, next) {

  function checkPermission () {
    const permissions = store.getters.authPermissions;

    if (permissions.length == 0) {
      store.dispatch('getUserInfo')
      .then(() => {
        checkPermission();
      });
    } else {
      const userCan = permissions.includes(to.matched[0].meta.permission);

      if (userCan) {
        next();
      } else {
        next('/permission-denied');
      }
    }
  }

  if (store.getters.isLoggedIn) {
    if (to.matched.some(record => record.meta.permission)) {
      checkPermission();
    } else {
      next();
    }
  } else {
    next('/login');
  }
}

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: { name: 'transfers.index' },
      beforeEnter: requireAuth,
    },
    {
      path: '/transfers',
      name: 'transfers.index',
      beforeEnter: requireAuth,
      component: () => import(/* webpackChunkName: "transfers.index" */ './views/Transfers.vue')
    },
    {
      path: '/transfers/create/:id?',
      name: 'transfers.create',
      beforeEnter: requireAuth,
      meta: {
        permission: 'create_transfer'
      },
      component: () => import(/* webpackChunkName: "transfers.create" */ './views/TransferRequest.vue')
    },
    {
      path: '/transfers/:id',
      name: 'transfers.show',
      beforeEnter: requireAuth,
      component: () => import(/* webpackChunkName: "transfers.show" */ './views/TransferDetails.vue')
    },
    {
      path: '/board',
      name: 'board',
      beforeEnter: requireAuth,
      meta: {
        permission: 'enter_board'
      },
      component: () => import(/* webpackChunkName: "board" */ './views/Board.vue')
    },
    {
      path: '/users',
      name: 'users.index',
      beforeEnter: requireAuth,
      meta: {
        permission: 'list_users'
      },
      component: () => import(/* webpackChunkName: "users.index" */ './views/Users.vue')
    },
    {
      path: '/users/create',
      name: 'users.create',
      beforeEnter: requireAuth,
      meta: {
        permission: 'create_user'
      },
      component: () => import(/* webpackChunkName: "users.create" */ './views/UserCreateOrEdit.vue')
    },
    {
      path: '/users/:id/edit',
      name: 'users.edit',
      beforeEnter: requireAuth,
      meta: {
        permission: 'edit_user'
      },
      props: route => ({ id: parseInt(route.params.id) }),
      component: () => import(/* webpackChunkName: "users.edit" */ './views/UserCreateOrEdit.vue')
    },
    {
      path: '/shuttles',
      name: 'shuttles.index',
      beforeEnter: requireAuth,
      meta: {
        permission: 'shuttle_list'
      },
      component: () => import(/* webpackChunkName: "shuttles.index" */ './views/ShuttleList.vue')
    },
    {
      path: '/shuttles/create',
      name: 'shuttles.create',
      beforeEnter: requireAuth,
      meta: {
        permission: 'shuttle_create'
      },
      component: () => import(/* webpackChunkName: "shuttles.create" */ './views/ShuttleCreateOrUpdate.vue')
    },
    {
      path: '/shuttles/:id',
      name: 'shuttles.show',
      beforeEnter: requireAuth,
      meta: {
        permission: 'shuttle_create'
      },
      component: () => import(/* webpackChunkName: "shuttles.show" */ './views/ShuttleCreateOrUpdate.vue')
    },
    {
      path: '/shuttle-register-list',
      name: 'shuttle_register_list',
      beforeEnter: requireAuth,
      meta: {
        permission: 'shuttle_register_list'
      },
      component: () => import(/* webpackChunkName: "shuttle_register_list" */ './views/ShuttleRegisterList.vue')
    },
    {
      path: '/shuttle-register',
      name: 'shuttle_register',
      beforeEnter: requireAuth,
      meta: {
        permission: 'shuttle_register'
      },
      component: () => import(/* webpackChunkName: "shuttle_register" */ './views/ShuttleRegister.vue')
    },
    {
      path: '/cars',
      name: 'cars.index',
      beforeEnter: requireAuth,
      meta: {
        permission: 'cars_list'
      },
      component: () => import(/* webpackChunkName: "cars.index" */ './views/Cars.vue')
    },
    {
      path: '/cars/:id/edit',
      name: 'cars.edit',
      beforeEnter: requireAuth,
      meta: {
        permission: 'cars_list'
      },
      props: route => ({ id: parseInt(route.params.id) }),
      component: () => import(/* webpackChunkName: "cars.edit" */ './views/CarsEdit.vue')
    },
    {
      path: '/addresses',
      name: 'addresses.index',
      beforeEnter: requireAuth,
      meta: {
        permission: 'address_create'
      },
      component: () => import(/* webpackChunkName: "addresses.index" */ './views/Addresses.vue')
    },
    {
      path: '/addresses/create',
      name: 'addresses.create',
      beforeEnter: requireAuth,
      meta: {
        permission: 'address_create'
      },
      component: () => import(/* webpackChunkName: "addresses.create" */ './views/AddressCreateOrEdit.vue')
    },
    {
      path: '/addresses/:id/edit',
      name: 'addresses.edit',
      beforeEnter: requireAuth,
      meta: {
        permission: 'address_update'
      },
      props: route => ({ id: parseInt(route.params.id) }),
      component: () => import(/* webpackChunkName: "addresses.edit" */ './views/AddressCreateOrEdit.vue')
    },
    {
      path: '/shifts',
      name: 'shifts.index',
      beforeEnter: requireAuth,
      meta: {
        permission: 'shift_create'
      },
      component: () => import(/* webpackChunkName: "shifts.index" */ './views/Shifts.vue')
    },
    {
      path: '/shifts/create',
      name: 'shifts.create',
      beforeEnter: requireAuth,
      meta: {
        permission: 'shift_create'
      },
      component: () => import(/* webpackChunkName: "shifts.create" */ './views/ShiftCreateOrEdit.vue')
    },
    {
      path: '/shifts/:id/edit',
      name: 'shifts.edit',
      beforeEnter: requireAuth,
      meta: {
        permission: 'shift_update'
      },
      props: route => ({ id: parseInt(route.params.id) }),
      component: () => import(/* webpackChunkName: "shifts.edit" */ './views/ShiftCreateOrEdit.vue')
    },
    {
      path: '/is-adet-raporu',
      name: 'is_adet_raporu',
      beforeEnter: requireAuth,
      meta: {
        permission: 'set_driver'
      },
      component: () => import(/* webpackChunkName: "is_adet_raporu" */ './views/IsAdetRaporu.vue'),
    },
    {
      path: '/profile',
      name: 'profile',
      beforeEnter: requireAuth,
      component: () => import(/* webpackChunkName: "profile" */ './views/Profile.vue')
    },
    {
      path: '/full-page/',
      component: () => import(/* webpackChunkName: "full-page" */ './views/FullPage.vue'),
      children: [
        {
          path: '/login/',
          name: 'login',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Login.vue'),
          meta: {
            guest: true,
          }
        },
        {
          path: '/password-reset/:token/:email/:host',
          name: 'passwordReset',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/PasswordReset.vue'),
          meta: {
            guest: true,
          }
        },
        {
          path: '/error-in-card',
          name: 'error-in-card',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Error.vue')
        },
        {
          path: '/permission-denied',
          name: 'permission-denied',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Error.vue'),
          props: { isInCard: false }
        },
        {
          path: '/lock-screen',
          name: 'lock-screen',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/LockScreen.vue'),
        },
        {
          path: '/404',
          name: 'not-found',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/NotFound.vue'),
          props: { isInCard: false }
        },
        {
          path: '/privacy-policy',
          name: 'privacy-policy',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/PrivacyPolicy.vue'),
        },
      ]
    },
    { path: '*', redirect: '/404' },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
