<template>
  <div>
    <aside-updates-item
      v-for="item in items"
      ref="notifylist"
      :key="item.id"
      :data="item.data"
      :date="item.created_at"
    />
    <div v-if="items.length == 0" class="is-centered">
      <b-notification :closable="false">
        <div class="has-text-centered">
          <b-icon icon="bell" size="is-large" />
        </div>
        <div class="has-text-centered">
          Bildiriminiz yok
        </div>
      </b-notification>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Echo from 'laravel-echo'
import AsideUpdatesItem from '@/components/AsideUpdatesItem'

export default {
  name: 'AsideUpdates',
  components: { AsideUpdatesItem },
  data () {
    return {
      items: [],
      echo: null,
      joined: false,
    }
  },
  computed: {
    ...mapState([
      'token',
      'user',
      'isAsideRightVisible'
    ]),
    connected () {
      if (this.echo) {
        return this.echo.connector.socket.connected;
      }
      return false;
    }
  },
  watch: {
    token (n) {
      if (n) {
        this.getData()
        this.connect()
      } else {
        if (this.connected) {
          this.echo.disconnect()
        }
      }
    },
    connected (n) {
      if (n) {
        this.joinChannel()
      }
    },
    user (n) {
      if (!this.joined && n.id) {
        this.joinChannel()
      }
    },
  },
  mounted () {
    if (this.token) {
      this.getData()
      this.connect()
    }

    document.addEventListener('click', this.clickCloseUpdates);
    document.addEventListener('touchstart', this.clickCloseUpdates);
  },
  methods: {
    getData () {
      this.$http
        .get('/notifications/list')
        .then(r => {
          this.items = r.data
          this.$emit('data-updated')
        })
        .catch(err => {
          this.$buefy.toast.open({
            message: `Error: ${err.message}`,
            type: 'is-danger'
          })
        })
    },
    connect () {
      window.io = require('socket.io-client');
      // if (document.URL.indexOf('local') > 0) return false;
      const socketURL = document.URL.indexOf('local') > 0 ? 'http://localhost:7007' : window.localStorage.getItem('base_url');
      this.echo = new Echo({
        host: socketURL,
        broadcaster: 'socket.io',
        namespace: 'App.Api.V1.Notifications',
        auth: {
          headers: {
            Authorization: `Bearer ${this.token}`
          },
        },
      })
    },
    joinChannel () {
      if (this.joined) return false;

      if (this.user.id) {
        let tenantId = window.localStorage.getItem('tenant_id')
        this.joined = true

        this.echo.private(`users.${tenantId}.${this.user.id}`)
          .notification((notification) => {
            console.log('notification => ', notification);
            if (this.$_.endsWith(notification.type, 'TransferNotify')) {
              this.items = [notification].concat(this.items);
              this.$store.commit('hasUpdates', true)

              setTimeout(() => {
                const lastNotify = this.$_.last(this.$refs.notifylist);
                this.$buefy.notification.open({
                  duration: 5000,
                  message: lastNotify.notifyText,
                  type: lastNotify.status
                })
              }, 100);
            }
        });

        this.echo.channel('boardcast')
          .listen('RefreshBoard', () => {
              console.log('RefreshBoard => Yes! ');
              this.$store.commit('boardRefresh', true)
          });
      }
    },
    clickCloseUpdates (e) {
      if (e.target.matches('.updates-icon')) return;

      if (this.isAsideRightVisible) {
        e.preventDefault();
        this.$store.commit('asideRightToggle')
      }
    }
  }
}
</script>
