<template>
  <div id="app">
    <nav-bar />
    <aside-menu
      :menu="menu"
      :menu-bottom="menuBottom"
      :class="{'has-secondary':!!menuSecondary}"
      @menu-click="menuClick"
    />
    <aside-menu
      v-if="menuSecondary"
      :menu="menuSecondary"
      :is-secondary="true"
      :label="menuSecondaryLabel"
      :icon="menuSecondaryIcon"
      @menu-click="menuClick"
      @close="menuSecondaryCloseClick"
    />
    <router-view :key="$route.fullPath" />
    <aside-right />
    <footer-bar />
    <overlay />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'
import Overlay from './components/Overlay'
import AsideRight from '@/components/AsideRight'

export default {
  name: 'App',
  components: {
    AsideRight,
    Overlay,
    FooterBar,
    AsideMenu,
    NavBar
  },
  data () {
    return {
      menuSecondary: null,
      menuSecondaryLabel: null,
      menuSecondaryIcon: null
    }
  },
  computed: {
    menu () {
      const menu = [
        {
          to: this.getRouter('transfers.index'),
          label: this.$t('transfers'),
          icon: 'cards-variant',
        },
        {
          to: this.getRouter('board'),
          label: this.$t('board'),
          icon: 'view-dashboard-variant'
        },
        {
          to: this.getRouter('shuttle_register_list'),
          label: this.$t('shuttleRegisterList'),
          icon: 'van-passenger'
        },
        {
          to: this.getRouter('shuttles.index'),
          label: this.$t('shuttlePlans'),
          icon: 'bus-clock'
        },
        {
          to: this.getRouter('users.index'),
          label: this.$t('users'),
          icon: 'account-group'
        },
        {
          to: this.getRouter('cars.index'),
          label: this.$t('cars'),
          icon: 'car-settings'
        },
        {
          to: this.getRouter('addresses.index'),
          label: this.$t('addresses'),
          icon: 'map-marker-radius'
        },
        {
          to: this.getRouter('shifts.index'),
          label: this.$t('shifts'),
          icon: 'calendar-clock'
        },
      ]

      if (this.checkPermission('set_driver')) {
        menu.push({
          label: 'Raporlar',
          subLabel: 'Raporlar',
          icon: 'newspaper-variant-multiple',
          menu: [
            {
              to: this.getRouter('is_adet_raporu'),
              label: 'İş Adet Raporu',
            },
          ]
        })
      }

      return [menu]
    },
    menuBottom () {
      return [
        {
          action: 'logout',
          icon: 'logout',
          label: this.$i18n.t('logOut'),
          state: 'info'
        }
      ]
    },
    ...mapState([
      'isOverlayVisible'
    ]),
  },
  watch: {
    isOverlayVisible (newValue) {
      if (!newValue) {
        this.menuSecondaryClose()
      }
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    menuClick (item) {
      if (item.menuSecondary) {
        this.menuSecondary = item.menuSecondary
        this.menuSecondaryLabel = item.menuSecondaryLabel ? item.menuSecondaryLabel : null
        this.menuSecondaryIcon = item.menuSecondaryIcon ? item.menuSecondaryIcon : null

        this.$store.commit('asideActiveForcedKeyToggle', item)
        this.$store.commit('asideActiveMenuKey', '')
        this.$store.commit('overlayToggle', true)
      } else if (item.action && item.action === 'logout') {
        this.$store.dispatch('logout')
          .then(() => this.$router.push('/login'))
          .catch(err => console.log(err));
      }
    },
    menuSecondaryCloseClick () {
      this.$store.commit('overlayToggle', false)
    },
    menuSecondaryClose () {
      this.menuSecondary = this.menuSecondaryLabel = this.menuSecondaryIcon = null
      this.$store.commit('asideActiveForcedKeyToggle', null)
    }
  }
}
</script>
