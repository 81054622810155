import Vue from 'vue';
import moment from 'moment';
import _ from 'lodash';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'
const humanizer = require('humanize-duration').humanizer()


Vue.filter('firstWord', (value) => {
  if (value) {
    return _.capitalize(value.replace(/ .*/,''));
  }
});


Vue.filter('formatDate', (value) => {
  let returnValue;
  if (value) {
    let locale = window.localStorage.getItem('lang') || 'en';
    returnValue = moment(value).locale(locale).format('DD MMMM HH:mm');
  }
  return returnValue;
});

Vue.filter('localeDate', (value, locale = 'tr-TR') => {
  if (!value) {
    return ''
  }

  return new Intl.DateTimeFormat(locale).format(new Date(value))
});

Vue.filter('formatDateYear', (value) => {
  let returnValue;
  if (value) {
    let locale = window.localStorage.getItem('lang') || 'en';
    returnValue = moment(value).locale(locale).format('DD MMMM YYYY');
  }
  return returnValue;
});

Vue.filter('formatDateTimeYear', (value) => {
  let returnValue;
  if (value) {
    let locale = window.localStorage.getItem('lang') || 'en';
    returnValue = moment(value).locale(locale).format('DD MMMM YYYY HH:mm');
  }
  return returnValue;
});


Vue.filter('humanTime', (time) => {
  humanizer.languages.en = {
    y: () => 'y',
    mo: () => 'mo',
    w: () => 'w',
    d: () => 'd',
    h: () => 'h',
    m: () => 'm',
    s: () => 's',
    ms: () => 'ms'
  }

  humanizer.languages.tr = {
    y: () => 'yıl',
    mo: () => 'ay',
    w: () => 'hafta',
    d: () => 'gün',
    h: () => 'saat',
    m: () => 'dk',
    s: () => 'sn',
    ms: () => 'ms'
  }

  time = time * 1000;

  return humanizer(time, {
    language: window.localStorage.getItem('lang') || 'en',
    units: ['y', 'mo', 'd', 'h', 'm'],
    round: true
  })
});

Vue.filter('showOnlyTime', (value) => {
  let returnValue;
  if (value) {
    returnValue = moment(value).format('HH:mm');
  }
  return returnValue;
});

Vue.filter('formatAddress', (value) => {
  const avrupaYakasi = [
    'Avrupa Yakası - Avrupa Yakası, İstanbul',
    'Avrupa Yakası, İstanbul, Türkiye',
  ];

  const anadoluYakasi = [
    'Anadolu Yakası - Anadolu Yakası, İstanbul',
    'Anadolu Yakası, İstanbul, Türkiye',
  ];

  value = value.replace(/undefined -/gi, '').trim();

  if (avrupaYakasi.includes(value)) {
    return 'Avrupa / İstanbul';
  }

  if (anadoluYakasi.includes(value)) {
    return 'Anadolu / İstanbul';
  }

  if (value.indexOf('/') > 0) {
    const arrValue = _.split(value, '/');
    if (_.size(arrValue) > 0) {
      let city = _.last(arrValue).split(', Turkey').join('');
      city = _.head(city.split(','));
      city = _.head(city.split('-'));
      const district = _.last(_.split(_.head(_.takeRight(arrValue, 2)), ' '));
      return `${district} / ${city}`;
    }
  }

  value = _.uniq(value.split(/[-,]/).map((item) => item.trim()));

  if (value.length > 1) {
    return `${_.nth(value, -2)} / ${_.last(value)}`;
  }

  return _.last(value);
});

Vue.filter('showStatus', (status) => {
  if (_.isInteger(status)) {
    const statusText = [
      'waiting',
      'wdriver',
      'wdeparture',
      'enroute',
      'completed',
      'cancel'
    ];
    return statusText[status];
  }
});

Vue.filter('phone', (number) => {
  if (number) {
    const asYouType = new AsYouType();
    asYouType.input(number);

    if (asYouType.getNumber()) {
      const formated = parsePhoneNumberFromString(asYouType.getNumber().number, asYouType.getNumber().country);
      return formated.formatInternational();
    } else {

      if (_.startsWith(number, '0')) {
        number = number.substring(1);
      }

      if (!_.startsWith(number, '+90')) {
        number = `+90${number}`;
      }
    }

    return number.replace(/[^0-9|+]/g, '')
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3 $4');
    }
});

Vue.filter('limitShow', (value, length = 20) => {
  return _.truncate(value, {
    length,
    'separator': /,? +/
  });
});
