import Vue from 'vue';
import store from './store';
import _ from 'lodash';

Vue.mixin({
  data: function () {
    return {
      // gMapsKey: process.env.VUE_APP_GKEY
    };
  },
  methods: {
    showSpinner () {
      document.documentElement.classList.add('has-spinner-active');
      const $this = this;
      setTimeout(function () {
        $this.hideSpinner();
      }, 10000);
    },
    hideSpinner () {
      document.documentElement.classList.remove('has-spinner-active');
    },
    checkPermission (permission) {
      if (!permission) return true;
      return store.getters.authPermissions.includes(permission);
    },
    hasRole (...roles) {
      if (!store.getters.authUser.attributes) {
        return false
      }

      return roles.includes(store.getters.authUser.attributes.role)
    },
    getRouter (name) {
      const router = this.$router.resolve({ name: name });
      return router ? router.route : '/404';
    },
    parseError (e) {
      return this.$_.flattenDeep(this.$_.values(e.response.data.errors)).join('<br>')
    },
    stringToCoordinate (string) {
      const locations = _.split(string, ',');

      return {
        lat: parseFloat(_.first(locations)),
        lng: parseFloat(_.last(locations))
      }
    },
    coordinateToArray (string) {
      return _.split(string, ',');
    }
  }
});
