<template>
  <b-dropdown
    mobile-modal
    hoverable
    class="navbar-item lang-bar"
    aria-role="list"
  >
    <b-button
      slot="trigger"
      rounded
    >
      <span style="margin-right:3.5px">
        {{ current }}
      </span>
    </b-button>

    <b-dropdown-item
      v-for="lng in langs"
      :key="lng"
      class="has-text-centered"
      aria-role="listitem"
      @click="updateLang(lng)"
    >
      <span>
        {{ lng }}
      </span>
      <img
        :src="require(`@/assets/flags/${lng}.png`)"
        width="24px"
        class="active"
        style="margin-bottom:-5px;"
      >
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'LangBar',
  components: {
  },
  data () {
    return {
      current: this.$i18n.locale,
      langs: this.$i18n.availableLocales
    }
  },
  methods: {
    updateLang (lang) {
      this.$i18n.locale = lang
      window.localStorage.setItem('lang', lang)
      this.current = lang
      const html = document.documentElement
      html.setAttribute('lang', lang)

      // Update User Lang
      this.$http
        .put('auth/set_lang', {
          lang: lang
        })
    }
  }
}
</script>
