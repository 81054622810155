<template>
  <article class="media">
    <div class="media-content">
      <div class="content">
        <router-link
          v-if="data.transfer_id"
          :to="{ name: 'transfers.show', params: { id: data.transfer_id }}"
          class="force-black"
          @click.native="updatesToggle"
        >
          {{ notifyText }}
        </router-link>
      </div>
      <nav class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <span v-if="icon" class="tag is-small" :class="status">
              <b-icon :icon="icon" size="is-small" />
            </span>
          </div>
          <div v-if="date" class="level-item">
            <small>{{ timeAgo }}</small>
          </div>
        </div>
      </nav>
    </div>
  </article>
</template>

<script>
import moment from 'moment'

export default {
  name: 'AsideUpdatesItem',
  props: {
    data: {
      type: Object,
      default: null
    },
    date: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      status: '',
      icon: '',
    }
  },
  computed: {
    timeAgo () {
      let locale = localStorage.getItem('lang') || 'en';
      return moment(this.date).locale(locale).from(moment())
    },
    notifyText () {
      let $value = '';

      if (this.data.type == 'status') {
        switch (this.data.status) {
          case 1:
            this.setStatusIcon('is-success', 'information')
            $value = this.$t('notify.approved', [ this.data.transfer_id ]);
            break
          case 2:
            this.setStatusIcon('is-info', 'information')
            $value = this.$t('notify.setDriver', [ this.data.transfer_id ])
            break
          case 5:
            this.setStatusIcon('is-danger', 'information')
            $value = this.$t('notify.rejected', [ this.data.transfer_id ])
            break
        }
      }

      if (this.data.type == 'leadstatus') {
        switch (this.data.status) {
          case 5:
            this.setStatusIcon('is-danger', 'information')
            $value = this.$t('notify.leadRejected', [ this.data.transfer_id ])
            break
        }
      }

      if (this.data.type == 'driver') {
        switch (this.data.status) {
          case 5:
            this.setStatusIcon('is-danger', 'information')
            $value = this.$t('notify.driverRejected', [ this.data.transfer_id ])
            break
          case 2:
            this.setStatusIcon('is-info', 'information')
            $value = this.$t('notify.driverSet', [ this.data.transfer_id ])
            break
          case 10:
            this.setStatusIcon('is-warning', 'information')
            $value = this.$t('notify.driverChanged', [ this.data.transfer_id ])
            break
          case 11:
            this.setStatusIcon('is-warning', 'information')
            $value = this.$t('notify.driverPlateChanged', [ this.data.transfer_id ])
            break
        }
      }

      if (this.data.type == 'admin') {
        switch (this.data.status) {
          case 0:
            this.setStatusIcon('is-info', 'information')
            $value = this.$t('notify.adminCreated', [ this.data.transfer_id ])
            break
        }
      }
      return $value;
    },
  },
  methods: {
    updatesToggle () {
      this.$store.commit('asideRightToggle')
    },
    setStatusIcon (status, icon) {
      this.status = status
      this.icon = icon
    }
  },
}
</script>
