/* Styles */
import '@/scss/main.scss';
import '@mdi/font/css/materialdesignicons.min.css';

/* Core */
import Vue from 'vue';
import Buefy from 'buefy';
import Axios from 'axios';
import VueI18n from 'vue-i18n';
import VuePageTitle from 'vue-page-title';
import lodash from 'lodash';
import { Loader } from 'google-maps';

/* Router & Store */
import router from './router';
import store from './store';
import './mixins';

/* Service Worker */
import './registerServiceWorker';
import './api/axios';
import './filters';
import messages from './lang';

/* Vue. Main component */
import App from './App.vue';

/* Vue. Component in recursion */
import AsideMenuList from '@/components/AsideMenuList';

import 'leaflet/dist/leaflet.css';

/* Collapse mobile aside menu on route change */
router.afterEach(() => {
  store.commit('asideMobileStateToggle', false);
  store.commit('overlayToggle', false);
  store.commit('asideActiveForcedKeyToggle', null);
});

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;
Vue.prototype.$_ = lodash;

/* These components are used in recursion algorithm */
Vue.component('AsideMenuList', AsideMenuList);

Vue.use(Buefy);
Vue.use(VueI18n);
Vue.use(VuePageTitle, {
  suffix: ' - ' + store.state.panelCompanyName
});


if (!window.localStorage.getItem('lang')) {
  window.localStorage.setItem('lang', 'en');
}

// https://github.com/davidkudera/google-maps-loader#readme
const loader = new Loader(process.env.VUE_APP_GKEY, {
  libraries: ['places'],
  region: 'TR',
  language: window.localStorage.getItem('lang'),
  // v: 3.45
});

loader.load();

const i18n = new VueI18n({
  locale: window.localStorage.getItem('lang'), // set locale
  messages // set locale messages
});

Vue.config.performance = true;

new Vue({
  i18n,
  router,
  store,
  mounted () {
    const token = localStorage.getItem('token');
    if (token) {
      this.$store.dispatch('getUserInfo');
      this.$http
        .get('info')
        .then(r => {
          if (r.data) {
            r.data.appname = r.data.appname.replace(/(^\w+)/,'<b>$1</b>');
            this.$store.commit('setAppInfo', r.data);
          }
        });
    }

    const html = document.documentElement;
    html.setAttribute('lang', this.$i18n.locale);
    document.documentElement.classList.remove('has-spinner-active');
  },
  render: h => h(App)
}).$mount('#app');

// Disable right click on production
document.oncontextmenu = document.body.oncontextmenu = () => process.env.NODE_ENV !== 'production';
