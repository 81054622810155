export default {
  addresses: 'Adresler',
  addressName: 'Adres Adı',
  addressNameSearch: 'Adres Adı Arama',
  addressSearch: 'Adres Arama',
  createAddress: 'Adres Oluştur',
  editAddress: 'Adres Düzenle',
  general: 'Genel',
  transfers: 'Transferler',
  details: 'Detaylar',
  fetching: 'Veri yükleniyor',
  nodata: 'Veri bulunamadı...',
  transferList: 'Transfer Listesi',
  newRequest: 'Yeni İstek',
  transferRequest: 'Transfer İsteği',
  transferDetails: 'Transfer Detayları',
  save: 'Kaydet',
  total: 'Toplam',
  approve: 'Onayla',
  aldim: 'Yolcuyu Aldım',
  biraktim: 'Yolcuyu Bıraktım',
  approved: 'Onaylandı',
  canceled: 'İptal Edildi',
  updated: 'Başarıyla güncellendi.',
  created: 'Başarıyla oluşturuldu.',
  reject: 'Reddet',
  cancelRequest: 'Talebi İptal Et',
  yes: 'Evet',
  no: 'Hayır',
  users: 'Kullanıcılar',
  board: 'Pano',
  createUser: 'Kullanıcı Oluştur',
  editUser: 'Kullanıcı Düzenle',
  edit: 'Düzenle',
  delete: 'Sil',
  loginFailed: 'Giriş Başarısız',
  username: 'Kullanıcı Adı',
  password: 'Şifre',
  passwordConfirmation: 'Şifre (tekrar)',
  error: 'İşlem sırasında hata oluştu.',
  errorApproved: 'İstek daha önce iptal edildiğinden onaylanmadı.',
  createDepartment: 'Departman Oluştur',
  departmentName: 'Departman Adı',
  create: 'Oluştur',
  close: 'Kapat',
  txtstatus: 'Durum',
  search: 'Ara',
  idSearch: 'ID Ara',
  nameSearch: 'İsim Arama',
  usernameSearch: 'Kullanıcı Adı',
  emailSearch: 'Eposta Ara',
  email: 'E-posta',
  emailAddress: 'E-posta Adresi',
  allRoles: 'Tüm Roller',
  allDepartments: 'Tüm Departmanlar',
  phoneSearch: 'Telefon Ara',
  dateSearch: 'Tarih Aralığı Seç',
  date: 'Tarih',
  departureSearch: 'Kalkış Arama',
  departure: 'Kalkış',
  targetSearch: 'Hedef Arama',
  driverSearch: 'Sürücü Arama',
  plateSearch: 'Plaka Arama',
  brandSearch: 'Marka Arama',
  modelSearch: 'Model Arama',
  brand: 'Marka',
  model: 'Model',
  totalVoyage: 'Sefer',
  totalDistance: 'KM',
  totalTime: 'Süre',
  shuttleTime: 'Saat',
  all: 'Tümü',
  transferDay: 'Transfer Günü',
  targetAddress: 'Hedef Adres',
  target: 'Hedef',
  startAddress: 'Başlangıç Adresi',
  address: 'Adres',
  comment: 'Yorum',
  aciklama: 'Açıklama',
  name: 'Ad',
  fullname: 'Ad & Soyad',
  phoneNumber: 'Telefon Numarası',
  phone: 'Telefon',
  checkFormValidation: 'Lütfen Formu Kontrol Ediniz',
  transferCreated: 'Transfer İsteği Oluşturuldu',
  copy: 'Kopyala',
  requestForm: 'İstek Formu',
  passenger: 'Yolcu',
  addPassenger: 'Yolcu Ekle',
  paginationShowing: 'Toplam {0} kayıttan {1} - {2} arası gösteriliyor.',
  plate: 'Plaka',
  driver: 'Sürücü',
  driverPhone: 'Sürücü Tel',
  chooseCar: 'Araç Seç',
  chooseDriver: 'Sürücü Seç',
  carTxt: 'Araç',
  clear: 'Temizle',
  filters: 'Filtrele',
  transferDuration: 'Süre',
  transferDistance: 'KM',
  showStat: 'Grafiği göster',
  hideStat: 'Grafiği gizle',
  exportExcel: 'Excel\'e aktar',
  importExcel: 'Excel\'den içeri aktar',
  clearFilter: 'Filtreyi Temizle',
  refresh: 'Yenile',
  uploadText: 'Yüklemek için dosyayı buraya sürükleyin veya tıklayın.',
  excelExampleFile: 'Örnek aktarım dosyasını indirmek için',
  click: 'tıklayın',
  transferInfo: 'Transfer Bilgileri',
  startLocation: 'Başlangıç Konumu',
  targetLocation: 'Bitiş Konumu',
  start: 'Başlangıç',
  directions: 'Yol Tarifi',
  estimatedTime: 'Tahmini Süre',
  driverCarInfo: 'Sürücü & Araç Bilgisi',
  rejectComment: 'İptal Nedeni',
  rejectUser: 'İptal Eden',
  personalInfo: 'Oluşturan Bilgisi',
  fullName: 'İsim Soyisim',
  tranferDate: 'Transf. Zamanı',
  createdDate: 'Oluşturma Tarihi',
  userComment: 'Oluşturan Yorumu',
  adminComment: 'Yönetici Yorumu',
  userForm: 'Kullanıcı Formu',
  selectDepartment: 'Departman Seçiniz',
  department: 'Departman',
  selectRole: 'Yetki Seçin',
  role: 'Yetki',
  enterStartAdress: 'Başlangıç Adresi',
  enterTargetAdress: 'Bitiş Adresi',
  startAdress: 'Başlangıç Adresi',
  targetAdress: 'Bitiş Adresi',
  login: 'Giriş Yap',
  sendResetLink: 'Sıfırlama Bağlantısı Gönder',
  passwordRecovery: 'Şifremi Unuttum',
  forgotPassword: 'Şifremi unuttum',
  company: 'Firma',
  additionalPassengers: 'Ek Yolcular',
  addDepartment: 'Departman Ekle',
  operationFailed: 'İşlem Başarısız. Lütfen Bilgilerinizi Kontrol Ediniz.',
  recoveryLinkSend: 'Şifre sıfırlama epostası gönderildi.',
  resetPassword: 'Şifremi Sıfırla',
  resetPasswordSuccess: 'Şifreniz başarıyla sıfırlandı.',
  myProfile: 'Profilim',
  logOut: 'Çıkış Yap',
  driverWarning: 'Seçilen sürücü aynı gün içerisinde {0} farklı göreve daha atanmıştır.',
  carWarning: 'Seçilen araç aynı gün içerisinde {0} farklı göreve daha atanmıştır.',
  availableCars: 'Müsait Araçlar',
  availableDrivers: 'Müsait Sürücüler',
  plannedCars: 'Planlanan Araçlar',
  enrouteCars: 'Yoldaki Araçlar',
  completions: 'Tamamlananlar',
  cars: 'Araçlar',
  carDetails: 'Araç Detayları',
  editCar: 'Araç Düzenle',
  seats: 'Koltuk Sayısı',
  shuttlePlanCreate: 'Yeni Servis Planı',
  shuttlePlanEdit: 'Servis Planı Düzenle',
  shuttlePlans: 'Servis Planları',
  shuttlePlanCreated: 'Servis Planı Başarıyla Oluşturuldu',
  shuttleRegisterList: 'Servis Listesi',
  shuttleCancel: 'Servis İsteği İptal Edildi.',
  moveShuttle: 'Servis Kaydına Çevir',
  newShuttleRegisterRequest: 'Yeni Servis İsteği',
  route: 'Güzergah',
  departureAddress: 'Alınacak Adres',
  arrivalAddress: 'Bırakılacak Adres',
  location: 'Konum',
  chooseLocation: 'Konum Seçiniz',
  choose: 'Seçiniz',
  enter: 'Giriş',
  exit: 'Çıkış',
  shuttleRegisterDetails: 'Kayıt Detayı',
  unassigned: 'Atanmamış',
  success: 'İşlem Başarılı',
  selected: 'Seçilen',
  daySelected: '{count} gün seçildi',
  dates: 'Tarihler',
  selfDriveRequest: 'Sürücüsüz Araç Talep Et',
  type: 'Tipi',
  description: 'Açıklama',
  transferMoveShuttle: 'Baş Şoförlük tarafından servis talebi olarak değiştirilmiştir. Servis Adı: {0} Saati: {1}',
  passengerOccupancyRate: 'Doluluk Oranı',
  shifts: 'Vardiyalar',
  createShift: 'Vardiya Oluştur',
  editShift: 'Vardiya Düzenle',
  shiftName: 'Vardiya Adı',
  owner: 'Sahibi',
  reviews: 'Değerlendirmeler',
  rating: 'Puan',
  author: 'Değerlendiren',
  ldapUser: 'LDAP Kullanıcısı',
  notify: {
    approved: '#{0} nolu talebiniz onaylandı',
    rejected: '#{0} nolu talebiniz iptal edildi',
    setDriver: '#{0} nolu talebiniz için araç ve sürücü atandı',
    leadRejected: 'Araç atanan #{0} nolu transfer iptal edildi.',
    driverSet: '#{0} nolu transfer isteği size atandı.',
    driverRejected: '#{0} nolu transfer iptal edildi.',
    adminCreated: 'Yeni transfer isteği oluşturuldu.',
    driverChanged: '#{0} nolu transfer isteğinin sürücüsü değiştirildi.',
    driverPlateChanged: '#{0} nolu transfer isteğinin araç plakası değiştirildi'
  },
  titles: {
    home: 'Anasayfa',
    login: 'Giriş Sayfası',
    transfers: 'Transfer Listesi',
    transfer: 'Transfer Details',
    users: 'Kullanıcı Listesi',
    createUser: 'Kullanıcı Oluştur',
    transferRequest: 'Transfer İsteği',
    board: 'Pano',
    newShuttlePlan: 'Yeni Servis Planı',
    shuttleRegisterList: 'Servis Kayıt Listesi',
    shuttleRegister: 'Servis Kaydı',
  },
  status: [
    'Onay bekleniyor',
    'Araç atanacak',
    'Kalkış için bekliyor',
    'Yolda',
    'Tamamlandı',
    'İptal edildi'
  ],
  userStatus: {
    true: 'Aktif',
    false: 'Pasif'
  },
  vehicleStatus: {
    true: 'Atandı',
    false: 'Bekliyor'
  },
  itemStatus: {
    true: 'Aktif',
    false: 'Pasif',
  },
  selfDrive: {
    true: 'Sürücüsüz',
    false: 'Normal',
  },
  dateTimePicker: {
    monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
    dayNames: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt']
  },
  filter: {
    id: 'ID',
    name: 'Ad Soyad',
    departure: 'Kalkış',
    target: 'Varış',
    driver: 'Sürücü',
    plate: 'Plaka',
    dateRange: 'Tarih',
    status: 'Durum',
    brand: 'Marka',
    model: 'Model',
    type: 'Tipi',
    self_drive: 'Tipi',
    address: 'Adres',
    address_name: 'Adres Adı',
    description: 'Açıklama',
    created_at: 'Oluşturulma Tarihi',
    starts_at: 'Başlangıç Tarihi',
    ends_at: 'Bitiş Tarihi',
    shift_name: 'Vardiya Adı',
    owner: 'Sahibi',
    user_department: 'Departman',
  },
  smartTable: {
    enter: 'Giriş',
    exit: 'Çıkış',
    active: 'Aktif',
    passive: 'Pasif',
    true: 'Evet',
    false: 'Hayır',
  }
};
